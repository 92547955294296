import {keys, pick} from 'lodash/object';

const STATUS_TYPE = {
  active: 'active',
  inactive: 'inactive',
  pending: 'pending',
  hidden: 'hidden',
  withdrawn: 'withdrawn',
  suspended: 'suspended',
  deleted: 'deleted',
  open: 'open',
  closed: 'closed',
  error: 'error',
  success: 'success',
  cancelled: 'cancelled',
  expired: 'expired',
  // new status for signed agreements
  declined: 'declined',
};

export const STATUS_LABEL = {
  [STATUS_TYPE.active]: 'Active',
  [STATUS_TYPE.inactive]: 'Inactive',
  [STATUS_TYPE.pending]: 'Pending',
  [STATUS_TYPE.hidden]: 'Draft', // 'hidden' status is displayed as 'draft'
  [STATUS_TYPE.withdrawn]: 'Withdrawn',
  [STATUS_TYPE.suspended]: 'Suspended',
  [STATUS_TYPE.deleted]: 'Deleted',
  [STATUS_TYPE.open]: 'Open',
  [STATUS_TYPE.closed]: 'Resolved',
  [STATUS_TYPE.error]: 'Error',
  [STATUS_TYPE.success]: 'Success',
  [STATUS_TYPE.cancelled]: 'Cancelled',
  [STATUS_TYPE.expired]: 'Expired',
  // new statuses for signed agreements
  [STATUS_TYPE.declined]: 'Declined',
};

export const ACCOUNT_STATUS = pick(STATUS_TYPE, [
    STATUS_TYPE.active,
    STATUS_TYPE.inactive,
    STATUS_TYPE.pending,
    STATUS_TYPE.suspended,
  ]),
  ACCOUNT_STATUS_LABEL = pick(STATUS_LABEL, keys(ACCOUNT_STATUS));

export const AB_STATUS = pick(STATUS_TYPE, [
    STATUS_TYPE.active,
    STATUS_TYPE.inactive,
    STATUS_TYPE.pending,
    STATUS_TYPE.suspended,
    STATUS_TYPE.withdrawn,
  ]),
  AB_STATUS_LABEL = pick(STATUS_LABEL, keys(AB_STATUS));

export const CB_STATUS = {
  active: 'active',
  inactive: 'inactive',
  suspended: 'suspended',
};

export const CB_STATUS_LABEL = {
  active: 'Active',
  inactive: 'Inactive',
  suspended: 'Suspended',
};

export const CB_ACCREDITATION_STATUS = {
  active: 'active',
  suspended: 'suspended',
  withdrawn: 'withdrawn',
};

export const CB_ACCREDITATION_STATUS_LABEL = {
  active: 'Accredited',
  suspended: 'Suspended',
  withdrawn: 'Withdrawn',
};

export const CB_AGREEMENTS_STATUS = {
  active: 'active',
  pending: 'pending',
  declined: 'declined',
};

export const CB_AGREEMENTS_STATUS_LABEL = {
  active: 'active',
  pending: 'pending',
  declined: 'declined',
};

export const CE_STATUS = pick(STATUS_TYPE, [
    STATUS_TYPE.active,
    STATUS_TYPE.inactive,
    STATUS_TYPE.pending,
    STATUS_TYPE.suspended,
    STATUS_TYPE.withdrawn,
  ]),
  CE_STATUS_LABEL = pick(STATUS_LABEL, keys(CE_STATUS));

export const COMPANY_STATUS = pick(STATUS_TYPE, [
    STATUS_TYPE.active,
    STATUS_TYPE.inactive,
    STATUS_TYPE.pending,
    STATUS_TYPE.suspended,
    STATUS_TYPE.withdrawn,
  ]),
  COMPANY_STATUS_LABEL = pick(STATUS_LABEL, keys(COMPANY_STATUS));

export const ACCREDITATION_STATUS = pick(STATUS_TYPE, [
    STATUS_TYPE.active,
    STATUS_TYPE.withdrawn,
  ]),
  ACCREDITATION_STATUS_LABEL = pick(STATUS_LABEL, keys(ACCREDITATION_STATUS));

// COMPANY LIST MATCHING STATUS
export const MATCH_STATUS = {
  matched: 'Matched',
  not_matched: 'Not Matched',
  pending: 'Pending',
  queued: 'Matching',
  running: 'Matching',
};

export const MATCH_STATUS_COLORS = {
  matched: 'success',
  not_matched: 'danger',
  pending: 'warning',
};

export const CERT_STATS = {
  valid: 'valid',
  invalid: 'invalid',
};

export const CERT_STATS_LABEL = {
  [CERT_STATS.valid]: 'Valid',
  [CERT_STATS.invalid]: 'Invalid',
};

export const CERT_STATS_COLORS = {
  [CERT_STATS.valid]: 'success',
  [CERT_STATS.invalid]: 'inactive',
};

export const COMPANY_LIST_ITEM_STATUS = {
  matched: 'matched',
  not_matched: 'not_matched',
  pending: 'pending',
  queued: 'queued',
  running: 'running',
};

export const ACCOUNT_PAYMENT_STATUS = {
  in_progress: 'in_progress',
  success: 'success',
  failed: 'failed',
};

export const MATCH_TYPE_STATUS = {
  exactly_matched: 'exactly_matched',
  loosely_matched: 'loosely_matched',
};

export const MATCH_TYPE_LABEL = {
  exactly_matched: 'Exact',
  loosely_matched: 'Loose',
};

export const VERIFICATION_METHOD_STATUS = {
  manual: 'manual',
  file: 'file',
  api: 'api',
};

export const VERIFICATION_METHOD_LABEL = {
  [VERIFICATION_METHOD_STATUS.manual]: 'Manual Search',
  [VERIFICATION_METHOD_STATUS.file]: 'File Upload',
  [VERIFICATION_METHOD_STATUS.api]: 'API',
};
