export const SUBSCRIPTION_STATUS = {
  active: 'active',
  suspended: 'suspended',
  pending: 'pending',
  canceled: 'canceled',
  trialing: 'trialing',
};

export const SUBSCRIPTION_STATUS_LABEL = {
  [SUBSCRIPTION_STATUS.active]: 'Active',
  [SUBSCRIPTION_STATUS.pending]: 'Pending',
  [SUBSCRIPTION_STATUS.suspended]: 'Suspended',
  [SUBSCRIPTION_STATUS.trialing]: 'Free Trial',
};

export const SUBSCRIPTION_CURRENCY = {
  usd: '$',
};

export const SUBSCRIPTION_CURRENCY_CODE = {
  USD: 'USD',
};

export const SUBSCRIPTION_BILLING_PERIOD = {
  annual: 'annual',
  monthly: 'monthly',
};

export const SUBSCRIPTION_BILLING_PERIOD_LABEL = {
  [SUBSCRIPTION_BILLING_PERIOD.annual]: 'Annual',
  [SUBSCRIPTION_BILLING_PERIOD.monthly]: 'Monthly',
};

export const SUBSCRIPTION_PLANS = {
  free: 'free',
  one_off: 'one_off',
};

export const SUBSCRIPTION_PLANS_LABEL = {
  free: 'Free',
  one_off: 'One-off Verification',
};

// New
export const SUBSCRIPTION_PLANS_TYPE = {
  free: 'free',
  basic: 'basic',
  standard: 'standard',
  premium: 'premium',
  enterprise: 'enterprise',
  pro: 'pro',
};

export const SUBSCRIPTION_PLANS_TYPE_COLOR = {
  [SUBSCRIPTION_PLANS_TYPE.free]: 'secondary',
  [SUBSCRIPTION_PLANS_TYPE.basic]: 'warning',
  [SUBSCRIPTION_PLANS_TYPE.standard]: 'primary',
  [SUBSCRIPTION_PLANS_TYPE.premium]: 'success',
  [SUBSCRIPTION_PLANS_TYPE.enterprise]: 'info',
};

export const SUBSCRIPTION_PLANS_TYPE_LABEL = {
  free: 'Free',
  pro: 'Pro',
  enterprise: 'Enterprise',
  basic: 'Basic',
  standard: 'Standard',
  premium: 'Premium',
};

export const VERIFICATION_ACCESS_LABEL = {
  expired: 'expired',
};

export const SUBSCRIPTION_SURVEY_TRIGGERS = {
  free_trial_cancellation: 'subscription_free_trial_cancellation',
};
