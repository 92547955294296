import styles from './im-sidebar.module.scss';

import React, {useEffect} from 'react';
import {get} from 'lodash/object';
import {Link, NavLink} from 'react-router-dom';

import {useAuth} from 'contexts/AuthContext';
import {ACCOUNT_ROLES, COMPANY_TYPES, COMPANY_STATUSES} from 'configs';
import classNames from 'classnames';
import {isEmpty} from 'lodash';
import Cookies from 'utils/cookies';
import {getFormattedNumber} from 'utils/numbers';
import {useGlobalCounts} from 'contexts/GlobalCountContext';
import {Label} from 'reactstrap';
import {ImageLogo} from 'components/Images';
import {useMembership} from 'contexts/MembershipContext';

function PrivateSidebarNavsItem(props) {
  const {nav} = props;
  const {to, content, className, data} = nav; //isNew

  return (
    <li>
      <NavLink to={to}>
        <div
          className={classNames(
            'd-flex align-items-center flex-row',
            className
          )}
        >
          <div className="flex-grow-1">{content}</div>
          <div>
            <Label
              className={classNames('mb-0 font-weight-normal', {
                'd-none': !data,
              })}
              style={{textDecoration: 'none'}}
            >
              {getFormattedNumber(data)}
            </Label>
          </div>
        </div>
      </NavLink>
    </li>
  );
}

function PrivateSidebarNavs(props) {
  const {value, hr = true} = props;
  const {header, navs} = value;

  if (isEmpty(navs)) {
    return false;
  }

  return (
    <>
      <div className="my-3">
        {!!header && <small>{header}</small>}
        <ul>
          {(navs || []).map((nav, index) => (
            <PrivateSidebarNavsItem key={index} nav={nav} />
          ))}
        </ul>
      </div>
      {!!hr && <hr />}
    </>
  );
}

function PrivateSidebar() {
  const auth = useAuth();
  const {isSubscribed, isTrial, hasPermit} = useMembership();
  const {globalCounts, getGlobalCounts} = useGlobalCounts();
  const companyType = get(auth, 'account.company.company_type', '');
  const isCE = companyType === COMPANY_TYPES.ce;
  const isCompany = get(auth, 'isCompany');

  const isAdmin = get(auth, 'account.account_role') === ACCOUNT_ROLES.admin;
  const isStaff = get(auth, 'account.account_role') === ACCOUNT_ROLES.staff;
  const isSuperAdmin =
    get(auth, 'account.account_role') === ACCOUNT_ROLES.super_admin;
  const isCompanySuspended =
    get(auth, 'account.company.company_status') === COMPANY_STATUSES.suspended;

  const {certifications_count, companies_count, watchlist_count} =
    globalCounts || {};

  const isVuAccount = isCE || isCompany;

  useEffect(() => {
    if (isVuAccount) {
      getGlobalCounts();
    }
  }, [isVuAccount]);

  const overviews = {
    header: 'Overview',
    navs: [
      {
        to: '/dashboard',
        content: 'Dashboard',
      },
      isCE && {
        to: '/certifications',
        content: 'My Certifications',
      },
    ].filter((v) => v),
  };

  const data = {
    header: 'Listing',
    navs: [
      {
        isNew: true,
        to: '/company-list',
        content: 'Companies',
        className: 'onboard-companies-tab',
        data: companies_count,
      },
      {
        isNew: true,
        to: '/certifications-list',
        content: 'Certifications',
        className: 'onboard-certs-tab',
        data: certifications_count,
      },
      (isSubscribed || (!isSubscribed && isSuperAdmin)) && {
        to: '/watch-list',
        content: 'Watchlist',
        isNew: isCE,
        data: watchlist_count,
      },
    ].filter((v) => v),
  };

  const verifications = {
    header: 'Bulk Verification',
    navs: [
      ((isStaff && isSubscribed) ||
        (isAdmin && isSubscribed) ||
        isSuperAdmin) && {
        isNew: true,
        to: '/import-management',
        content: 'Import Company Data',
        className: 'onboard-import-tab',
      },
      (isSubscribed || isTrial) && {
        isNew: true,
        to: '/import-settings',
        content: 'Import Settings',
        className: 'onboard-import-settings-tab',
      },
      hasPermit('master_api_key.access') && {
        isNew: true,
        to: '/api-verification',
        content: 'API Settings',
      },
    ].filter((v) => v),
  };

  const message = {
    header: 'Messages',
    navs: [
      {
        to: '/enquiries',
        content: 'Enquiries',
      },
    ],
  };

  const history = {
    header: 'History',
    navs: [
      {
        to: '/search-history',
        content: 'Search History',
        isNew: isCE,
      },
    ],
  };

  const administration = {
    header: 'Administration',
    navs: [
      isSuperAdmin && {to: '/users', content: 'Users'},
      isCE && {to: '/ce-profile', content: 'Company Profile'},
      isCompany && {
        to: '/company-profile',
        content: 'Company Profile',
      },
      isSuperAdmin && {
        isNew: true,
        to: '/membership',
        content: 'Membership & Billing',
        className: 'onboard-membership-tab',
      },

      {to: '/settings', content: 'Settings'},
    ].filter((v) => v),
  };

  const navigation = (
    <>
      <PrivateSidebarNavs value={overviews} />
      <PrivateSidebarNavs value={data} />
      {!isEmpty(verifications) && <PrivateSidebarNavs value={verifications} />}
      <PrivateSidebarNavs value={message} />
      <PrivateSidebarNavs value={history} />
      <PrivateSidebarNavs value={administration} hr={false} />
    </>
  );

  const navigationSuspended = (
    <>
      <PrivateSidebarNavs value={overviews} />
      <PrivateSidebarNavs value={administration} hr={false} />
    </>
  );

  return (
    <nav
      className={classNames(styles['private-sidebar'], {
        [styles['private-sidebar-height']]: !Cookies.consent,
      })}
    >
      <div
        className={classNames('border-bottom', styles['private-sidebar--logo'])}
      >
        <Link to="/">
          <ImageLogo size="lg" />
        </Link>
      </div>
      {isCompanySuspended ? navigationSuspended : navigation}
    </nav>
  );
}

export default PrivateSidebar;
