function enable(value) {
  if (
    value === null ||
    value === 0 ||
    value === '0' ||
    value === false ||
    value === 'false'
  ) {
    return false;
  }

  if (
    value === undefined ||
    value === 1 ||
    value === '1' ||
    value === true ||
    value === 'true'
  ) {
    return true;
  }

  return false;
}

// Activation
export const ENABLE_ACTIVATION = enable(
  process.env.REACT_APP_ENABLE_ACTIVATION
);

// Registration
export const ENABLE_REGISTRATION = enable(
  process.env.REACT_APP_ENABLE_REGISTRATION
);

// Invitation
export const ENABLE_INVITATION = enable(
  process.env.REACT_APP_ENABLE_INVITATION
);

// Dashboard
export const ENABLE_PROGRESS = enable(process.env.REACT_APP_ENABLE_PROGRESS);

// CB Public page
export const ENABLE_CB_CONTACT_US = enable(
  process.env.REACT_APP_ENABLE_CB_CONTACT_US
);

// AB Public page
export const ENABLE_AB_CONTACT_US = enable(
  process.env.REACT_APP_ENABLE_AB_CONTACT_US
);

// CE Public page
export const ENABLE_CE_CONTACT_US = enable(
  process.env.REACT_APP_ENABLE_CE_CONTACT_US
);

// Certification Public page
export const ENABLE_CERT_WATCH = enable(
  process.env.REACT_APP_ENABLE_CERT_WATCH
);
export const ENABLE_CERT_CONTACT_US = enable(
  process.env.REACT_APP_ENABLE_CERT_CONTACT_US
);

// Enquiries
export const ENABLE_ENQUIRIES = enable(process.env.REACT_APP_ENABLE_ENQUIRIES);

/// Users
export const ENABLE_USERS = enable(process.env.REACT_APP_ENABLE_USERS);

export const ENABLE_USERS_CREATE = enable(
  process.env.REACT_APP_ENABLE_USERS_CREATE
);
export const ENABLE_USERS_READ = enable(
  process.env.REACT_APP_ENABLE_USERS_READ
);
export const ENABLE_USERS_UPDATE = enable(
  process.env.REACT_APP_ENABLE_USERS_UPDATE
);
export const ENABLE_USERS_DELETE = enable(
  process.env.REACT_APP_ENABLE_USERS_DELETE
);

export const ENABLE_COMPANY_PROFILE = enable(
  process.env.REACT_APP_ENABLE_COMPANY_PROFILE
);

///
/// Settings

// Settings - User
export const ENABLE_SETTINGS_USER = enable(
  process.env.REACT_APP_ENABLE_SETTINGS_USER
);

// Settings - Security
export const ENABLE_SETTINGS_SECURITY = enable(
  process.env.REACT_APP_ENABLE_SETTINGS_SECURITY
);

// Settings - Notifications
export const ENABLE_SETTINGS_NOTIFICATIONS = enable(
  process.env.REACT_APP_ENABLE_SETTINGS_NOTIFICATIONS
);

// Settings - Accounts
export const ENABLE_SETTINGS_ACCOUNTS = enable(
  process.env.REACT_APP_ENABLE_SETTINGS_ACCOUNT
);

export const ENABLE_WATCH_LIST = enable(
  process.env.REACT_APP_ENABLE_WATCH_LIST
);

export const ENABLE_SEARCH_HISTORY = enable(
  process.env.REACT_APP_ENABLE_SEARCH_HISTORY
);

// Deprecated as of 02-20-25
// export const ENABLE_SUBSCRIPTION = enable(
//   process.env.REACT_APP_ENABLE_SUBSCRIPTION
// );
