import React from 'react';

import {Nav} from 'reactstrap';

import PrivateNavPlan from './PrivateNavPlan';
import PrivateNavUser from './PrivateNavUser';
import PrivateNavLimit from './PrivateNavLimit';
import PrivateNavSearch from './PrivateNavSearch';
import PrivateNavNotification from './PrivateNavNotification';

import {useAuth} from 'contexts/AuthContext';
import {useLimits} from 'contexts/LimitsContext';
import {usePage} from 'contexts/PageContext';

import {debounce, get, isEqual} from 'lodash';
import {IconFA} from 'components/Icons';
import styles from './styles.module.scss';

import {ACCOUNT_ROLES, COMPANY_TYPES, COMPANY_STATUSES} from 'configs';
import {useMembership} from 'contexts/MembershipContext';
import {
  SUBSCRIPTION_BILLING_METHOD,
  SUBSCRIPTION_TYPE_LEVEL,
} from 'configs/membership';

function PrivateNav(props) {
  const {dark, isMobile} = props;
  const {setSidebar, isOpenLeftSidebar, sidebar} = usePage();

  const auth = useAuth();
  const {data} = useLimits();
  const isSuperAdmin =
    get(auth, 'account.account_role') === ACCOUNT_ROLES.super_admin;

  const isCompany = get(auth, 'isCompany');
  const accountType = get(auth, 'companyType');
  const isCE = accountType === COMPANY_TYPES.ce;

  const isVU = isCE || isCompany;

  const isCompanySuspended =
    get(auth, 'account.company.company_status') === COMPANY_STATUSES.suspended;

  const toggleSidebar = debounce(() => {
    setSidebar('left', !isOpenLeftSidebar, true);
  }, 100);

  const {isSubscribed, subscriptionLevel, billingMethod} = useMembership();
  const showLimits = subscriptionLevel <= SUBSCRIPTION_TYPE_LEVEL.standard;

  const {available_limit_left} = data;

  const isManualMethod = isEqual(
    billingMethod,
    SUBSCRIPTION_BILLING_METHOD.manual
  );
  const showNavLimits = available_limit_left >= 0 && isSubscribed && showLimits;
  const showNavPlan =
    isSuperAdmin && subscriptionLevel !== 10 && !isManualMethod;

  return (
    <>
      {!!sidebar && (
        <span
          onClick={toggleSidebar}
          className="d-xl-none p-1 ml-2 text-primary cursor-pointer"
        >
          <IconFA name="bars" />
        </span>
      )}
      <div className={styles['private__nav__search__container']}>
        {!isCompanySuspended && (
          <PrivateNavSearch isMobile={isMobile} hasSidebar={!!sidebar} />
        )}
      </div>

      <Nav className="align-items-center ml-auto flex-row" navbar>
        {isVU && (
          <>
            {showNavLimits && <PrivateNavLimit />}
            {showNavPlan && <PrivateNavPlan />}
          </>
        )}

        <PrivateNavNotification dark={dark} />

        <PrivateNavUser />
      </Nav>
    </>
  );
}

export default PrivateNav;
