const COMPANY_LIST = {
  ACCESS: 'company_list.access',
  TAGS: {
    ACCESS: 'company_list.tags.access',
  },
};

const CERTIFICATIONS = {
  ACCESS: 'certificates.access',
  TAGS: {
    ACCESS: 'certificates.tags.access',
  },
};

const WATCHLIST = {
  ACCESS: 'watchlist.access',
};

const IMPORT_COMPANY_DATA = {
  ACCESS: 'import_company_data.access',
};

const API_SETTINGS = {
  ACCESS: 'master_api_key.access',
};

const ANALYTICS = {
  ACCESS: 'analytics.access',
};

export const PERMISSIONS = {
  COMPANY_LIST,
  CERTIFICATIONS,
  WATCHLIST,
  IMPORT_COMPANY_DATA,
  API_SETTINGS,
  ANALYTICS,
};
