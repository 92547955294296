import React from 'react';
import {BaseButton} from 'components/Buttons';

import {useLimits} from 'contexts/LimitsContext';
import {useMembership} from 'contexts/MembershipContext';
import {useSubscription} from 'contexts/SubscriptionContext';

function PrivateNavPlan() {
  const {isTrial, subscriptionLevel, isTrialDone} = useMembership();
  const {data} = useLimits();

  const {
    total_limit,
    cert_limit_reached,
    available_limit_left,
    company_limit_reached,
  } = data;

  const {onUpgrade} = useSubscription();

  const isNearLimit = (available_limit_left / total_limit) * 100 <= 30; // show only if 30% left

  const enableUpgrade =
    (subscriptionLevel === 0 && !isTrial) ||
    isNearLimit ||
    cert_limit_reached ||
    company_limit_reached;

  if (!enableUpgrade) return null;

  return (
    <div className="d-none d-sm-block">
      <BaseButton
        onClick={onUpgrade}
        className="mr-3 rounded-sm"
        color="danger"
        outline
      >
        {!isTrialDone && !subscriptionLevel
          ? 'Try Basic Plan for Free'
          : 'Upgrade'}
      </BaseButton>
    </div>
  );
}

export default PrivateNavPlan;
