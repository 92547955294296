import React from 'react';
import {generatePath} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {PROGRESS_KEYS, PROGRESS_LINKS, PROGRESS_HASHES} from 'configs/progress';
import {COMPANY_TYPES, ROUTER_SCROLL_TYPES} from 'configs';

function generateProgressLinks(type, account, action) {
  const {company} = account || {};
  const {company_id, company_type, is_default} = company || {};

  let link;

  switch (type) {
    case PROGRESS_KEYS.company_profile: {
      if (company_type === COMPANY_TYPES.ce) {
        link = {
          pathname: PROGRESS_LINKS.ce_company_profile,
          hash: PROGRESS_HASHES.company_profile,
          state: {
            fromProgress: true,
            scroll: ROUTER_SCROLL_TYPES.scroll,
          },
        };
      }
      if (company_type === COMPANY_TYPES.company)
        link = {
          pathname: PROGRESS_LINKS.company_company_profile,
          hash: PROGRESS_HASHES.company_profile,
          state: {
            fromProgress: true,
            scroll: ROUTER_SCROLL_TYPES.scroll,
          },
        };
      if (
        (company_type === COMPANY_TYPES.cb ||
          company_type === COMPANY_TYPES.ab) &&
        is_default
      )
        link = {
          pathname: generatePath(PROGRESS_LINKS.default_company_profile, {
            branchId: company_id,
          }),
          state: {
            fromProgress: true,
          },
        };

      if (
        (company_type === COMPANY_TYPES.cb ||
          company_type === COMPANY_TYPES.ab) &&
        !is_default
      )
        link = {
          pathname: PROGRESS_LINKS.not_default_company_profile,
          state: {
            fromProgress: true,
          },
        };
      if (company_type === COMPANY_TYPES.mncb)
        link = {
          pathname: PROGRESS_LINKS.not_default_company_profile,
          state: {
            fromProgress: true,
          },
        };
      break;
    }
    case PROGRESS_KEYS.company_logo: {
      if (company_type === COMPANY_TYPES.ab) {
        link = {
          pathname: PROGRESS_LINKS.ab_company_profile,
          hash: PROGRESS_HASHES.company_logo,
          state: {
            fromProgress: true,
          },
        };
      }
      if (company_type === COMPANY_TYPES.cb) {
        link = {
          pathname: PROGRESS_LINKS.cb_company_profile,
          hash: PROGRESS_HASHES.company_logo,
          state: {
            fromProgress: true,
          },
        };
      }
      if (company_type === COMPANY_TYPES.ce) {
        link = {
          pathname: PROGRESS_LINKS.ce_company_profile,
          hash: PROGRESS_HASHES.company_logo,
          state: {
            fromProgressLogo: true,
          },
        };
      }
      if (company_type === COMPANY_TYPES.company) {
        link = {
          pathname: PROGRESS_LINKS.company_company_profile,
          hash: PROGRESS_HASHES.company_logo,
          state: {
            fromProgressLogo: true,
          },
        };
      }
      break;
    }
    case PROGRESS_KEYS.user_information: {
      link = {
        pathname: PROGRESS_LINKS.user_information,
        hash: PROGRESS_HASHES.user_information,
        state: {
          fromProgress: true,
          scroll: ROUTER_SCROLL_TYPES.scroll,
        },
      };
      break;
    }
    case PROGRESS_KEYS.user_two_factor_auth: {
      link = {
        pathname: PROGRESS_LINKS.user_two_factor_auth,
        hash: PROGRESS_KEYS.user_two_factor_auth,
        state: {
          scroll: ROUTER_SCROLL_TYPES.scroll,
        },
      };
      break;
    }
    default: {
      link = '';
    }
  }

  return (
    <Link to={link || ''} onClick={() => action()} className="stretched-link" />
  );
}

export default generateProgressLinks;
